import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/privacyPolicy"
import { useEffect } from "react";
import TermsOfUse from "./pages/TermsOfUse";

function App() {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [location.pathname])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location.hash])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </div>
  );
}

export default App;
