import React, { useEffect, useState } from "react";
import {
  instagramImg,
  tiktokImg,
  logo
} from "../../images";
import { Link } from "react-router-dom";

const Header = () => {
  const [scrollPosition, setscrollPosition] = useState();
  const handleScroll = () => {
    setscrollPosition(window.scrollY);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg fixed-top ${
          scrollPosition > 60 ? "scroll" : ""
        }`}
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" className="img-fluid" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <div className="offcanvas-title" id="offcanvasNavbarLabel">
                <img src={logo} alt="" className="img-fluid" />
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body justify-content-end">
              <ul className="social-media-links d-flex ps-0 align-items-center mb-0">
                {/* <li>
                  <a href="https://instagram.com/versusmediaa">
                    <img src={facebbookImg} alt="" className="img-fluid" />
                  </a>
                </li> */}
                <li>
                  <a href="https://instagram.com/versusmediaa" target="_blank" rel="noreferrer">
                    <img src={instagramImg} alt="" className="img-fluid" />
                  </a>
                </li>
                {/* <li>
                  <a href="https://instagram.com/versusmediaa">
                    <img src={twitterImg} alt="" className="img-fluid" />
                  </a>
                </li> */}
                <li>
                  <a href="https://www.tiktok.com/@versus.media" target="_blank" rel="noreferrer">
                    <img src={tiktokImg} alt="" className="img-fluid" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
