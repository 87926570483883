import React from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <>
      <Layout>
        <div className="terms-of-use">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-4">Terms of Use</h2>
                <h4 className="mb-2 mt-4">In short…</h4>
                <p>
                  The following key points of the Terms of Use are brought for
                  your convenience only. They do not substitute the full Terms.
                </p>
                <ul>
                  <li>
                    <strong>VersusMedia Service.</strong> VersusMedia offer an
                    online advice service based on crowd wisdom that offers
                    people to ask for questions or make descisons on two options
                    they choose from.
                  </li>
                  <li>
                    <strong>Age restriction.</strong> You must be 13 years of
                    age or older in order to register to the Service.
                  </li>
                  <li>
                    <strong>Privacy.</strong> Content that you post at the
                    Service is made available publicly. It is not treated as
                    private or confidential and you should not have any
                    expectation of privacy with respect to it. Our Privacy
                    Policy{" "}
                    <a href="https://versusmedia.app/policy">
                      https://versusmedia.app/policy
                    </a>
                    , which is incorporated to these Terms by reference,
                    explains the privacy practices on the Service.
                  </li>
                  <li>
                    <strong>Fees.</strong> App Subscription premium may be
                    subject to a payment of applicable Fees for users who choose
                    to register to that service.
                  </li>
                  <li>
                    <strong>Restriction.</strong> You shall not use the Service
                    in any way, make any action through the Service or make any
                    information or materials available through the Service that
                    promotes or encourages self-harm or may cause risk or any
                    other harm to user who will perform it.
                  </li>
                  <li>
                    <strong>Content you contribute.</strong> We do not claim
                    ownership over content that originates from users or third
                    parties. However, in order to provide the Service and its
                    features, you give us permission to use content that you
                    share through the Service such as text, photos, videos etc.
                  </li>
                  <li>
                    <strong>Intellectual property.</strong> All legal rights in
                    the Service, including all intellectual property rights, are
                    VersusMedia’s.
                  </li>
                  <li>
                    <strong>Disclaimer of warranty.</strong> VersusMedia is a
                    platform. Anyone can make posts available subject to these
                    Terms. We do not guarantee and make no representation or
                    judgment as to the safety of performing any of the actions
                    OR descisions, or the Content’s legality, appropriateness,
                    or adequacy to your age or needs.
                  </li>
                  <li>
                    <strong>Commercial content.</strong> The Service may contain
                    various forms of ads or other commercial Content. Their
                    inclusion in the Service does not constitute our
                    recommendation or encouragement to procure the goods or
                    services advertised.
                  </li>
                  <li>
                    <strong>Commercial messages.</strong> You provide us with
                    your express consent to receive commercial messages from us
                    or others on our behalf. You may withdraw your consent and
                    notify us that you refuse to receive commercial messages.
                  </li>
                  <li>
                    <strong>Removal of inappropriate content.</strong> If you
                    find any content within the Service that infringes these
                    Terms, please report it to us at info@versusmedia.app.
                    Requests to remove content that infringes the copyright of
                    others.
                  </li>
                  <li>
                    THE TERMS INCLUDE ADDITIONAL PROVISIONS THAT YOU SHOULD
                    CAREFULLY READ, SUCH AS PROVISIONS REGARDING WARRANTY,
                    LIMITED LIABILITY, INDEMNIFICATION AND ASSIGNMENT.
                  </li>
                  <li>
                    <strong>Governing Law & Dispute Resolution.</strong> USE OF
                    THE SERVICE IS GOVERNED BY THE LAWS OF THE STATE OF NEW
                    YORK. MOST DISPUTES BETWEEN US WILL BE DECIDED IN
                    ARBITRATION. YOU CAN OPT OUT OF ARBITRATION UNDER THE TERMS
                    OUTLINED BELOW, IN WHICH CASE DISPUTES WILL BE DECIDED IN
                    COURT.
                  </li>
                </ul>
                <p>
                  <strong>
                    These terms do not, and are not intended to, obligate you to
                    agree to any outcome of the final results of the posts,
                    actually perform the tasks described in any post or any
                    content documenting you performing an action.
                  </strong>
                </p>
                <p>
                  <strong>
                    Your use of the service and performance of your post results
                    is made at your own sole responsibility.
                  </strong>
                </p>
                <h4 className="mb-2 mt-4">… and in detail</h4>
                <p>
                  Welcome to VersusMedia, an online Service that allows users
                  <strong>Users</strong> to ask for questions OR desicions based and get feedback
                  from the users of app for a suggestion of a better choice for
                  them.(the <strong>“Service”</strong> ). Throughout these Terms, both of you will
                  be referred to as <strong>“you”</strong> or <strong>“your”</strong>.
                </p>
                <p>
                  The Service is owned and operated by Passly Ltd. ( <strong>“we”, “our”,
                  “us”</strong> ). It is available through our designated mobile
                  application (the <strong>“App”</strong> ). Through the Service, you may view and
                  post content related to other posts or questions or descisions
                  in textual, audio or visual form (or any combination thereof;
                  collectively, the <strong>“Content”</strong> ). Your access and use of the
                  Service is made through a smartphone device (the <strong>“Device”</strong> ).
                </p>
                <p>
                  Please read carefully the following Terms of Use (the
                 <strong> "Terms"</strong> ). By using or accessing the Service, or by registering
                  as a user with the Service, you agree to be bound by these
                  Terms. If you do not agree to the Terms, you may not use the
                  Service. Use of the Service may be subject to additional terms
                  and conditions that govern the use of Apple or Android
                  devices, application marketplaces or other services that
                  interact with the App. You alone are responsible for complying
                  with such additional terms and conditions.
                </p>
                <p>
                  These terms apply to all users in the App. If any of their
                  provisions are directed solely at a User, this shall be
                  expressly stated to you.
                </p>
                <p>
                  These terms do not, and are not intended to, obligate you to
                  agree to ask a question or descision, actually perform the
                  final result of the post described in any post. To the maximum
                  extent permitted by law, your use of the service and
                  performance of any action, is at your own sole responsibility.
                </p>
                <p>
                  Note that use of the Service may involve the use of third
                  party platforms, such as Facebook, etc. For example, you may
                  share Content or invite your friends to the Service, through
                  these third party platforms. Use of such third party platforms
                  is governed by their respective terms of service, not these
                  Terms. You bear the sole and exclusive responsibility for
                  complying with those other terms of service.
                </p>
                <h4 className="mb-2 mt-4">Age restriction</h4>
                <p>
                  If you are a minor under 18 years of age, you may not use the
                  Service without your parent or legal guardian consent to Terms
                  and Privacy Policy. By registering with the Service, you
                  declare that you are at least 18 years old or that you are a
                  parent or legal guardian registering on behalf of your child.
                  We reserve the right to terminate an account, if we find that
                  you registered as minor under 18. We may request additional
                  information to confirm your age and your parent consent at any
                  time. We recommend parents to monitor minor’s use of the
                  Service.
                </p>
                <h4 className="mb-2 mt-4">Use of the Service</h4>
                <p>
                  Subject to these Terms, Users may use of the Service free of
                  charge. subscribers use of the Service ( <strong>“Subscription”  </strong> ) may be
                  subject to a payment of applicable fees (the <strong>“Fees”</strong> ).{" "}
                </p>
                <p>
                  The service is an online place that brings together people who
                  want to make their descions based on crowd-wisdom. The service
                  enables Users to ask for questions or descsion they want to
                  make and get feedback from the crowd digitaly. Users who wish
                  to ask a question or advice need to think before take any
                  actions and understand the outcome in advance. Users who
                  upload a post will get votes from the other users based on
                  their preferences on the main screen( <strong>"Feed"</strong> ). Each user will
                  be able to access to part of the statistics such as Gender,
                  Age, Country, Views, etc... and can pay to be a subscriber to
                  access all of that data and can make their descison by their
                  own choice based on the results. VersusMedia can be used to
                  make day to day descisions or to compare between two images or
                  looks etc.. by ask the crowd for it.
                </p>
                <h4 className="mb-2 mt-4">
                  Registration and Subscription Terms
                </h4>
                <p>
                  You are fully accountable for any outcome that may result from
                  your failure to provide true, accurate and complete details in
                  the course of registration.
                </p>
                <p>
                  You are prohibited from selling or transferring your Account
                  or making it available to anyone else. You are solely
                  responsible and liable for all activities performed with or
                  through your Account. To prevent unauthorized use, keep your
                  password confidential and do not share it with any third
                  party.
                </p>
                <p>
                  <strong>User Account.</strong> To use the Service, you’ll need to register and
                  create a personal account ( <strong>“Account”</strong> ), by providing your email
                  address and setting-up an account username and password. Only
                  individuals with full legal capacity may create a User Account
                  and use the Service. When you register to the Service, we will
                  ask you to provide us the details we describe in our Privacy
                  Policy <a href="https://versusmedia.app/policy">https://versusmedia.app/policy</a> ( <strong>“Registration
                  Information”</strong> ).
                </p>
                <h4 className="mb-2 mt-4">Commercial messages</h4>
                <p>
                  By submitting your contact details, you provide us with your
                  express consent to receive commercial messages from us or
                  others on our behalf. at any time, you may withdraw your
                  consent and notify us that you refuse to receive commercial
                  messages, altogether or of specific kind, by sending a notice
                  of consent withdrawal.
                </p>
                <h4 className="mb-2 mt-4">
                  Account suspension and termination
                </h4>
                <p>
                  In addition to any remedies that may be available to us under
                  any applicable law, we may at any time, in our sole discretion
                  and without prior notice, temporarily or permanently, deny,
                  limit, suspend, terminate or delete your Account, prohibit you
                  from accessing the Service, remove, hide, disable or render
                  inaccessible Content that you posted on the Service and take
                  technical and legal measures to keep you off the Service, if
                  we believe that you misused the Service or breached these
                  Terms. These may include, but are not limited to, instances in
                  which we believe you have damaged our goodwill or reputation,
                  or where you’ve posted content prohibited under the
                  instructions set out in the section below.
                </p>
                <h4 className="mb-2 mt-4">Fees and Billing</h4>
                <p>
                  We may offer the Service (or some of its features) to
                  Subscribers on a trial-basis, free-of-charge, which may be
                  time-limited. Beyond this trial-basis, the Service may be
                  offered to Subscribers subject to a payment of applicable
                  Fees, in accordance with the packages, schemes and amounts
                  presented to you upon Subscribing to the Service or at a later
                  time ( <strong>“Plans”</strong> ). We may change our payment terms at any time
                  and will seek your consent to the payment of those Fees. If
                  you do not consent, we may terminate your Subscription.
                </p>
                <p>
                  <strong>Currency and Rates.</strong> All Fees are quoted in US Dollars, unless
                  expressly stated otherwise.
                </p>
                <p>
                  If applicable, we will charge you for Fees using the payment
                  method you provide ( <strong>“Billing Information”</strong> ). You represent and
                  warrant that you are lawfully permitted to use your selected
                  payment method. You must keep the Billing Information you
                  provided to us current, complete, and accurate, and notify us
                  promptly in case of any change in your Billing Information.
                </p>
                <p>
                  By Subscribing to the Service and choosing your Plan, you give
                  your consent to being billed for the Fees, in addition to any
                  applicable taxes and surcharges or commissions charged by the
                  payment processor. All amounts payable to us are exclusive of
                  any excise, sales tax, VAT, withholding tax or other
                  governmental charges or transaction charges. You are
                  responsible for the payment of all such applicable taxes or
                  charges.
                </p>
                <p>
                  <strong>Refund or Cancellation.</strong> The Fees you pay are non-refundable.
                  You are responsible for paying all applicable Fees whether or
                  not you actually used or otherwise benefited from the Service.
                </p>
                <p>
                  Your Billing Information may be processed and handled through
                  relevant third-party payment processors and therefore subject
                  to the terms and conditions of these third parties pursuant to
                  your contractual relations with them.
                </p>
                <p>
                  <strong>Failing to Pay.</strong> Failing to settle your payments for Fee-Based
                  Services will prevent you from continuing to use the Service,
                  without regard to any other remedies available to us under
                  applicable law.
                </p>
                <h4 className="mb-2 mt-4">
                  Use of the Service and restrictions
                </h4>
                <p>
                  Posting Content such as images or videos on the Service may
                  requires the App’s access to your Device’s camera, microphone
                  and local storage.
                </p>
                <p>
                  <strong>Prohibited use.</strong> While using the Service, you agree to refrain
                  from –
                </p>
                <ul>
                  <li>
                    Breaching the Terms or any other applicable rules and
                    instructions that we may convey with respect to the use of
                    the Service;
                  </li>
                  <li>
                    Engaging in any activity that constitutes or encourages
                    conduct that would constitute a criminal offense, give rise
                    to civil liability or otherwise violate any applicable law,
                    including laws governing privacy, defamation, spam and
                    copyright.
                  </li>
                  <li>
                    Interfering with, burdening or disrupting the functionality
                    of the Service;
                  </li>
                  <li>
                    Breaching the security of the Service or identifying any
                    security vulnerabilities in it;
                  </li>
                  <li>
                    Circumventing or manipulating the operation or functionality
                    of the Service, or attempting to enable features or
                    functionalities that are otherwise disabled, inaccessible or
                    undocumented in the Service;
                  </li>
                  <li>
                    Using or launching any automated system, including without
                    limitation robots, crawlers and similar applications to
                    collect or compile content from the Service;
                  </li>
                  <li>
                    Displaying the Service or any part thereof in an exposed or
                    concealed frame, or linking to elements or portions on or of
                    the Service, independently from manner on which they
                    originally appear or are made available through the Service;
                  </li>
                  <li>
                    Impersonating any person or entity, or making any false
                    statement pertaining to your identity, employment, agency or
                    affiliation with any person or entity;
                  </li>
                  <li>
                    Collecting, harvesting, obtaining or processing personal
                    information of or about other users of the Service.
                  </li>
                  <li>
                    Abusing, harassing, threatening or intimidating other users
                    of the Service;
                  </li>
                </ul>
                <p>
                  Without derogating from any of the above, you may not post,
                  disseminate, transmit or otherwise communicate through the
                  Service any Content which:
                </p>
                <ul>
                  <li>
                    May infringe rights of others, including patents,
                    copyrights, trade secrets, trademarks, a person's right to
                    privacy or right of publicity;
                  </li>
                  <li>
                    May encourage or promote self-harm, or pose a risk or cause
                    any other harm to anyone who performs a decision;
                  </li>
                  <li>
                    May depict or identify minors, their personal details, their
                    address or ways to contact them;
                  </li>
                  <li>
                    May include software viruses, spyware or any other malicious
                    applications;
                  </li>
                  <li>
                    May encourage, support, assist, or advise in the commission
                    of a criminal offense or carry an elevated risk of
                    psychological or physiological stress or injury or damage to
                    property;
                  </li>
                  <li>
                    May, through posting, publication, dissemination, or making
                    available to the public, be prohibited by any applicable
                    law, including court orders;
                  </li>
                  <li>
                    May be threatening, abusive, harassing, defamatory,
                    libelous, vulgar, violent, obscene or racially, ethnically
                    or otherwise objectionable;
                  </li>
                  <li>
                    May include unsolicited commercial communications ('spam'),
                    chain letters, or pyramid schemes.
                  </li>
                </ul>

                <p>
                  You may not access or use the Service in order to develop or
                  create a similar or competitive App or Service.
                </p>
                <p>
                  You may not use the Service to send invitations or
                  notifications to anyone who has not expressed their consent to
                  receive such invitations or notifications from you.
                </p>
                <p>
                  <strong>We encourage respectful behavior.</strong> If you find any content on
                  the App that violates these Terms of Use, please use the
                  report link or report user buttons. You may also submit a
                  feedback by contacting us at: info@versusmedia.app.
                </p>
                <h4 className="mb-2 mt-4">Content</h4>
                <p>
                  Posts and Content presented through the Service may originate
                  from us, from you, from other users. You may find that the
                  Content is not compatible with your requirements,
                  objectionable, annoying, improper, or immoral.
                </p>
                <p>
                  We encourage you to treat any Content posted on or through the
                  Service with caution and discretion. We do not guarantee and
                  make no representation or judgment as to the safety of
                  performing any of the Posts, the Content’s legality,
                  appropriateness, authenticity, validity, accuracy,
                  truthfulness, morality, adequacy to your age or needs, uses
                  thereof that third parties may make, or as to any offerings,
                  dealings, transactions or interactions made by or between you
                  and third parties in connection with the Service.
                </p>
                <p>
                  The inclusion of any Content on or through the Service does
                  not constitute our endorsement, sponsorship, recommendation or
                  encouragement to suggest, or agree to perform, any Action,
                  engage in any interaction, activity, transaction or dealings
                  with any third party, or an authorization or representation of
                  our affiliation with any third party.
                </p>
                <p>
                  Any and all communications and interactions between you and
                  third parties, and all consequences resulting from the above,
                  are strictly between you and such third party, and you assume
                  full and exclusive responsibility for them. We are not a party
                  to those communications, interactions, dealings, engagements
                  and transactions.
                </p>
                <p>
                  We do not normally examine, approve or disqualify Content that
                  you or others have uploaded or posted, but we reserve the
                  right to do so.
                </p>
                <p>
                  We do not claim ownership over the Content that you create,
                  upload to or post on the Service. When you use the service to
                  do this, you represent and warrant to us that:
                </p>
                <ul>
                  <li>
                    You are the rightful owner of all rights to such Content, or
                    are lawfully licensed by all the rightful owners to upload
                    to, and post such Content on, the Service;
                  </li>
                  <li>
                    You are lawfully entitled to grant us the licenses in and to
                    such Content, as contemplated by these Terms;
                  </li>
                  <li>
                    You lawfully obtained the consent of all individuals
                    depicted, shown or referred to in the Content, to include
                    them in the Content and to use that Content for the purposes
                    and in the manner contemplated by these terms. With respect
                    to minors depicted, shown or referred to in the Content, you
                    lawfully obtained the consent of their parents or legal
                    guardians, as well as such minors approval;
                  </li>
                  <li>
                    Creating the Content, uploading it to and posting it on the
                    Service, does not infringe any rights of third parties,
                    including intellectual property rights, privacy rights and
                    publicity rights.
                  </li>
                </ul>
                <p>
                  Subject to your rights and undertakings under the Privacy
                  Policy, by posting or uploading Content on or to the Service,
                  you grant us a royalty-free, free-of-charge, worldwide,
                  non-exclusive, sub-licensable and transferrable, license to
                  use such Content (including commercial use as well as copying,
                  distributing, posting and making derivative works), on or
                  through the Service and on any other online or offline
                  platform or media, for as long as you have not deleted such
                  Content from the Service. You further waive all moral rights
                  and rights of attribution with respect to your Content.
                </p>
                <p>
                  Any Content that you provide through the Service may be
                  available to all Users.
                </p>
                <p>
                  We may at any time, at our sole discretion and without prior
                  notice to you, remove, hide, disable, render inaccessible, or
                  modify any Content you have created, uploaded, streamed or
                  posted on or through the Service, without any liability to
                  you, if we find such Content in violation of these Terms.
                </p>
                <h4 className="mb-2 mt-4">Your privacy</h4>
                <p>
                  We respect your privacy. Our Privacy Policy
                  <a href="https://versusmedia.app/policy">https://versusmedia.app/policy</a>, which is incorporated to these
                  Terms by reference, explains the privacy practices on the
                  Service.
                </p>
                <h4 className="mb-2 mt-4">Intellectual property</h4>
                <p>
                  <strong>Our intellectual property.</strong> We and our licensors own all
                  rights, title and interest in and to the Service, including
                  patents, copyrights, trademarks, trade names, service marks,
                  trade secrets and other intellectual property rights, and any
                  goodwill associated therewith, but excluding any content that
                  originates from you, are the exclusive property of Passly and
                  its licensors.
                </p>
                <p>
                  <strong>Restrictions.</strong> Unless as expressly permitted in the Terms, you
                  may not copy, distribute, display or perform publicly,
                  sublicense, decompile, disassemble, reduce to human readable
                  form, execute publicly, make available to the public, adapt,
                  make commercial use of, process, compile, translate, sell,
                  lend, rent, reverse engineer, combine with other software,
                  modify or create derivative works of the Service, any part
                  thereof or any of the Content on or of the Service (except for
                  your own Content), either by yourself or by a third party on
                  your behalf, in any way or by any means, including, but not
                  limited to electronic, mechanical or optical means.
                </p>
                <p>
                  You may not adapt or otherwise use, including in any Internet
                  domain name, any name, mark or logo that is similar to our
                  marks and logos. You must refrain from any action or omission
                  which may dilute or tarnish our goodwill.
                </p>
                <h4 className="mb-2 mt-4">
                  Requests to remove Copyrighted Content from the Service
                </h4>
                <p>We respect the intellectual property rights of others. </p>
                <p>
                  Requests to remove Content due to copyright infringement must
                  be made in accordance with our <strong>Copyright Policy </strong>
                  <a href="https://versusmedia.app/copyright">https://versusmedia.app/copyright</a>. After receiving a request
                  to remove or re-post Content on the Service, we will review
                  the request and take action as necessary.
                </p>
                <h4 className="mb-2 mt-4">
                  Changes in the Service; discontinuation
                </h4>
                <p>
                  We may, but are not obligated to, maintain the Service with
                  periodic releases of bug fixes, code updates or upgrades. We
                  will determine, in our discretion, the frequency and scope of
                  such releases and you will have no plea, claim or demand
                  against us or our directors, officers, employees, agents,
                  advisors, consultants, subcontractors and assignees
                  (collectively, our <strong>“Staff”</strong> ), for any of these releases or the
                  lack thereof.
                </p>
                <p>
                  You grant us your express consent to remotely send and
                  automatically install on your Device, without any prior or
                  additional notice, updates, upgrades, code modifications,
                  enhancements, bug fixes, improvements and any other form of
                  code or settings changes in or to the App, which, among other
                  things, may change the App's settings, layout, design or
                  display.
                </p>
                <p>
                  We may also, at any time and without prior notice, change the
                  layout, design, scope, features or availability of the Service
                  or the Content.
                </p>
                <p>
                  Such changes, by their nature, may cause inconvenience or even
                  malfunctions. You agree and acknowledge that we do not assume
                  any responsibility with respect to, or in connection with the
                  introduction of such changes or from any malfunctions or
                  failures that may result therefrom.
                </p>
                <p>
                  We may, at any time, at our sole discretion, discontinue,
                  terminate or suspend the operation of the Service, or any part
                  or aspects thereof, temporarily or permanently, without any
                  liability to you.
                </p>
                <h4 className="mb-2 mt-4">
                  Service support, availability and quality
                </h4>
                <p>
                  The availability, quality and functionality of the Service
                  depends on various factors, including software, hardware,
                  communication networks, and the quality of cellular network
                  connectivity, which are provided by third parties, at their
                  responsibility. These factors are not fault-free.
                </p>
                <p>
                  We do not warrant that the service will operate without
                  disruption, errors or interruptions, or that it will be
                  accessible, or available at all times or immune from errors,
                  glitches or unauthorized access.
                </p>
                <p>
                  You acknowledge that the Service does not provide any data
                  back-up services.
                </p>
                <h4 className="mb-2 mt-4">Changes to the Terms</h4>
                <p>
                  We may amend the Terms from time to time. In such case, we
                  will notify you of the amended Terms, through e-mail or
                  through a pop-up when you access the Apps. If you do not
                  accept the amended Terms, we may terminate the Terms and your
                  User Account and block your access to, and use of, the
                  Service. Your continued use of the Service after the effective
                  date of the amended Terms constitutes your consent to the
                  amended Terms. The latest version of the Terms will be
                  accessible through the App.
                </p>
                <h4 className="mb-2 mt-4">DISCLAIMER OF WARRANTY</h4>
                <p>
                  THE SERVICE IS PROVIDED FOR USE “AS IS” AND “WITH ALL FAULTS”.
                  WE AND OUR STAFF DISCLAIM ALL WARRANTIES AND REPRESENTATIONS,
                  EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE, THE
                  APPS, THE POSTS, THE CONTENT, YOUR ACCOUNT, ANY INTERACTION
                  RELATED TO THE SERVICE, ANY THIRD PARTY SOFTWARE OR HARDWARE,
                  INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, QUALITY, RELIABILITY, NON-INFRINGEMENT,
                  TITLE, COMPATIBILITY, PERFORMANCE, AVAILABILITY, SAFETY,
                  SECURITY OR ACCURACY.
                </p>
                <h4 className="mb-2 mt-4">LIMITATION OF LIABILITY</h4>
                <p>
                  WE, INCLUDING OUR STAFF, SHALL NOT BE LIABLE TO THE MAXIMUM
                  EXTENT PERMITTED BY THE APPLICABLE LAW, FOR ANY DIRECT,
                  INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, STATUTORY, INCIDENTAL
                  OR CONSEQUENTIAL DAMAGE, OR ANY SIMILAR DAMAGE OR LOSS
                  (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES
                  AND PAYMENTS, EITHER IN TORT (INCLUDING NEGLIGENCE), CONTRACT,
                  OR IN ANY OTHER FORM OR THEORY OF LIABILITY, ARISING FROM, OR
                  IN CONNECTION WITH THE SERVICE, THE CONTENT, THE USE OF, OR
                  THE INABILITY TO USE THE SERVICE OR YOUR ACCOUNT, OR ANY
                  COMMUNICATION OR INTERACTIONS WITH THE SERVICE, OR ANY ACTIONS
                  YOU HAVE PERFORMED, OR ANY COMMUNICATIONS, INTERACTIONS,
                  DEALINGS, ENGAGEMENTS AND TRANSACTIONS WITH OTHERS ON OR
                  THROUGH THE SERVICE, OR YOUR RELIANCE UPON ANY CONTENT POSTED
                  OR AVAILABLE ON THE SERVICES, OR ANY FAILURE, ERROR, OR
                  BREAKDOWN IN THE FUNCTION OF THE SERVICE, OR ANY RELIANCE MADE
                  BY YOU ON THIRD PARTY SOFTWARE OR HARDWARE, OR ANY FAULT, OR
                  ERROR MADE BY OUR STAFF, OR ANY DENIAL OR CANCELATION OF YOUR
                  ACCOUNT, OR RETENTION, DELETION, DISCLOSURE AND ANY OTHER USE
                  OR LOSS OF YOUR CONTENT ON THE SERVICE.
                </p>
                <h4 className="mb-2 mt-4">Indemnification</h4>
                <p>
                  To the maximum extent permitted by law, you agree to
                  indemnify, defend and hold harmless, us and our Staff, at your
                  own expense and immediately after receiving a written notice
                  thereof, from and against any damages, loss, costs and
                  expenses, including attorney’s fees and legal expenses,
                  resulting from any plea, claim, allegation or demand, arising
                  from, or in connection with your use of the Service, any
                  Content, your performance of any Actions, your breach of the
                  Terms, any other terms, rules or regulations applicable to the
                  Service, your violation, or infringement of any other person’s
                  rights.
                </p>
                <h4 className="mb-2 mt-4">
                  Links and Commercial Information in the Service
                </h4>
                <p>
                  We may incorporate ads or information of commercial nature in
                  the Service. In addition, Content you view through the Service
                  may contain various forms of ads, or commercial or promotional
                  information or content. Such ads and commercial content
                  originate from third parties, and we cannot guarantee their
                  reliability or accuracy. The inclusion of such ads and
                  commercial content in or through the Service does not
                  constitute our recommendation or encouragement to purchase the
                  goods or services advertised. We assume no responsibility or
                  liability for any goods or services advertised or marketed
                  though the Service, as well as with regard to any information
                  provided regarding them, including their availability.
                </p>
                <p>
                  The Service may contain links to content published on other
                  websites or external sources, provided by third parties. We do
                  not operate or monitor these websites and content. You may
                  find them, or the information and content posted therein not
                  compatible with your requirements, or you may object to their
                  content, or find such content to be annoying, improper,
                  unlawful or immoral. By linking to a certain website, we do
                  not endorse, or sponsor its content, or confirm its accuracy,
                  credibility, authenticity, reliability, validity, integrity,
                  or legality. We assume no responsibility or liability for such
                  third party websites or content, or their availability.
                </p>
                <h4 className="mb-2 mt-4">Application Marketplace</h4>
                <p>
                  Your use of the Service may be subject to additional third
                  party terms and conditions that govern that application
                  marketplace from which you downloaded the App, such as iTunes,
                  Google Play or Amazon App-store for Android. Such third
                  parties are not responsible for providing maintenance and
                  support services with respect to the App.
                </p>
                <p>
                  The following terms apply if you downloaded an App from
                  Apple's App Store. You and us agree and acknowledge as
                  follows:
                </p>
                <p>
                  These Terms are concluded between yourself and us, and not
                  with Apple Inc. ("Apple"). Apple is not responsible for the
                  App. In the event of a conflict between these Terms and the
                  Usage Rules set forth for Licensed Applications (as defined in
                  the App Store Terms of Service) or the App Store Terms of
                  Service as of the Effective Date (that is when you indicated
                  your agreement to be bound by these Terms, by clicking the
                  appropriate button), the Usage Rules or the App Store Terms of
                  Service will prevail. If any provision of these Terms is less
                  restrictive than the corresponding provision of the Usage
                  Rules or the App Store Terms of Service, the Usage Rules or
                  the App Store Terms of Service will prevail.
                </p>
                <p>
                  You may use the App on an iPhone or an iPad that you own or
                  control. Apple has no obligation whatsoever to furnish any
                  maintenance and support services with respect to the App.
                </p>
                <p>
                  Apple is not responsible for addressing any claims by you or
                  any third party relating to the App or your possession and/or
                  use of the App, including (i) product liability claims, (ii)
                  any claim that the App fails to conform to any applicable
                  legal or regulatory requirement, and (iii) claims arising
                  under consumer protection or similar legislation.
                </p>
                <p>
                  In the event of any third party claim that the App or your
                  possession and use of the App infringes that third party’s IP
                  Rights, Apple will not be responsible for the investigation,
                  defense, settlement and discharge of any such infringement
                  claim.
                </p>
                <p>
                  Apple and Apple’s subsidiaries are third party beneficiaries
                  of these Terms. Upon Your acceptance of these Terms, Apple
                  will have the right (and will be deemed to have accepted the
                  right) to enforce these Terms against you as a third party
                  beneficiary thereof. You represent and warrant that (i) you
                  are not located in a country that is subject to a U.S.
                  Government embargo, or that has been designated by the U.S.
                  Government as a “terrorist supporting” country; and (ii) You
                  are not listed on any U.S. Government list of prohibited or
                  restricted parties.
                </p>
                <h4 className="mb-2 mt-4">Termination of these Terms</h4>
                <p>
                  You may, at any time terminate these Terms by doing all the
                  following:
                </p>
                <ul>
                  <li>
                    Completely removing the App from all of your Devices; and
                  </li>
                  <li>
                    Providing us written notice of termination by contacting us
                    at info@versusmedia.app.
                  </li>
                </ul>
                <p>
                  Termination of these Terms will not preclude our continued use
                  of Content you posted and Public Profile Information we have
                  obtained, prior to termination.
                </p>
                <p>
                  We may terminate these Terms and your license to use the
                  Service, at any time if we determine that you breached these
                  Terms, by issuing you a notice of such termination, blocking
                  your access to and use of the Service, or in any other manner
                  contemplated by these Terms. Upon termination by The Service
                  you must discontinue any and all use of the Service and
                  uninstall the App or Apps from all of your Devices.
                </p>
                <h5>Consequences of termination</h5>
                <p>
                  See our privacy policy for details regarding retention and
                  deletion of your personal information.
                </p>
                <p>
                  The following sections shall survive any termination, or
                  expiration of the Terms: Content and dealings, Your privacy,
                  Intellectual property, Requests to remove Content from the
                  Service, Limitation of Liability, Indemnification,
                  Consequences of termination, Governing Law & Jurisdiction,
                  General.
                </p>
                <h4 className="mb-2 mt-4">Governing Law & Jurisdiction</h4>
                <p>
                  Regardless of your place of residence or where you access or
                  use the Service from, these Terms and your use of the Service
                  will be governed by and construed solely in accordance with
                  the laws of the State of New York, USA, excluding any
                  otherwise applicable rules of conflict of laws, which would
                  result in the application of the laws of a jurisdiction other
                  than the State of New York.
                </p>
                <p>
                  ANY AND ALL DISPUTES, CLAIMS OR CONTROVERSIES BETWEEN YOU AND
                  PASSLY LTD. REGARDING THESE TERMS OR THE USE OF THE SERVICE,
                  WHICH ARE NOT AMICABLY RESOLVED, SHALL BE SETTLED THROUGH
                  BINDING ARBITRATION (RATHER THAN IN COURT) BY TELEPHONE,
                  ONLINE OR BASED SOLELY UPON WRITTEN SUBMISSIONS WITHOUT
                  IN-PERSON APPEARANCE, ADMINISTERED BY THE AMERICAN ARBITRATION
                  ASSOCIATION (AAA), UNDER ITS CONSUMER ARBITRATION RULES (WHICH
                  ARE AVAILABLE AT WWW.ADR.ORG).
                </p>
                <p>
                  JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR(S) MAY BE
                  ENTERED IN ANY COURT HAVING JURISDICTION THEREOF.
                </p>
                <p>
                  HOWEVER, YOU MAY LODGE CLAIMS IN SMALL CLAIMS COURT IF YOUR
                  CLAIM QUALIFIES.
                </p>
                <p>
                  THE FEDERAL ARBITRATION ACT AND FEDERAL ARBITRATION LAW APPLY
                  TO THESE TERMS.
                </p>
                <p>
                  THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF
                  AN ARBITRATION AWARD IS LIMITED. HOWEVER, AN ARBITRATOR CAN
                  AWARD ON AN INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A
                  COURT (INCLUDING INJUNCTIVE AND DECLARATORY RELIEF OR
                  STATUTORY DAMAGES), AND MUST FOLLOW THESE TERMS AS A COURT
                  WOULD.
                </p>
                <p>
                  PAYMENT OF FILING, ADMINISTRATION AND ARBITRATOR FEES WILL BE
                  GOVERNED BY THE AAA'S CONSUMER ARBITRATION RULES. THESE FEES
                  WILL BE SHARED AS FOLLOWS: ONE THIRD BY YOU AND TWO THIRDS BY
                  US, UNLESS THE ARBITRATOR: (I) DETERMINES THAT THE CLAIMS ARE
                  FRIVOLOUS, IN WHICH CASE THE CLAIMANT SHALL BEAR ALL SUCH FEES
                  ARISING FROM THE FRIVOLOUS CLAIM; OR (II) DETERMINES THAT THE
                  FEES SHOULD BE ALLOCATED DIFFERENTLY.
                </p>
                <p>
                  YOU AND PASSLY LTD. HEREBY ACKNOWLEDGE, AGREE AND COVENANT
                  THAT ANY DISPUTES SHALL ONLY BE ADJUDICATED IN ARBITRATION ON
                  AN INDIVIDUAL BASIS, AND NOT IN CLASS, COLLECTIVE,
                  CONSOLIDATED OR REPRESENTATIVE PROCEEDINGS.
                </p>
                <p>
                  YOU MAY OPT-OUT OF THE ABOVE ARBITRATION CLAUSE BY EMAILING US
                  TO info@versusmedia.app, WITHIN 21 DAYS OF YOU ENTERING INTO
                  THESE TERMS FOR THE FIRST TIME, AN OPT-OUT NOTICE THAT
                  IDENTIFIES YOURSELF AND CLEARLY SETS OUT YOUR CHOICE TO OPT
                  OUT OF DISPUTE RESOLUTION BY ARBITRATION. IN CASE OF SUCH
                  OPT-OUT, ANY AND ALL DISPUTES, CLAIMS OR CONTROVERSIES BETWEEN
                  YOU AND US REGARDING THESE TERMS OR THE USE OF THE SERVICE,
                  WHICH ARE NOT AMICABLY RESOLVED, SHALL BE SUBJECT TO THE
                  EXCLUSIVE JURISDICTION AND VENUE OF THE STATE AND FEDERAL
                  COURTS IN NEW YORK COUNTY IN THE STATE OF NEW YORK, USA.
                </p>
                <p>
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS SECTION,
                  EITHER PARTY MAY FILE: (A) AN IMPLEADER CLAIM AGAINST THE
                  OTHER PARTY IN ANY COURT OF COMPETENT JURISDICTION; (B) IN ANY
                  COURT OF COMPETENT JURISDICTION A CLAIM CONCERNING THE
                  INFRINGEMENT (OR ALLEGED INFRINGEMENT) OF INTELLECTUAL
                  PROPERTY RIGHTS (INCLUDING COPYRIGHTS AND TRADE SECRETS).
                </p>
                <h4 className="mb-2 mt-4">General</h4>
                <p>
                  Whenever used in these Terms, the term "Including", whether
                  capitalized or not, means without limitation to the preceding
                  phrase. All examples and e.g. notations are illustrative, not
                  exhaustive.
                </p>
                <p>
                  These Terms constitute the entire agreement between you and us
                  concerning the subject matter herein, and supersede all prior
                  and contemporaneous negotiations and oral representations,
                  agreements and statements.
                </p>
                <p>
                  These Terms may only be modified by written amendment duly
                  executed by us.
                </p>
                <p>
                  Failure on our part to demand performance of any provision in
                  the Terms shall not constitute a waiver of any of our rights
                  under the Terms.
                </p>
                <p>
                  You may not assign or transfer your rights and obligations
                  under the Terms without our prior written consent, which
                  consent shall not be unreasonably withheld. Any attempted or
                  actual assignment by you, without our prior consent, shall be
                  null and void. Notwithstanding the provisions of the
                  Assignment of Obligations Law, we may assign these Terms in
                  their entirety, including all right, duties, liabilities and
                  obligations therein, upon notice to you and without obtaining
                  your further specific consent, to a third-party, upon a
                  merger, acquisition, change of control or the sale of all or
                  substantially all of its equity or assets relating to the
                  Agreement. By virtue of such assignment, the assignee assumes
                  our stead, including all right, duties, liabilities and
                  obligations.
                </p>
                <p>
                  If any provision of the Terms is held to be invalid by a
                  competent court, then the provision shall be performed and
                  enforced to the maximum extent permitted by law. The remaining
                  provisions of the Terms shall continue to remain in full force
                  and effect.
                </p>
                <h4 className="mb-2 mt-4">Contact Us</h4>
                <p>
                  At any time, you may contact us with any question, request,
                  comment or complaint that you may have with respect to the
                  Service or these Terms that you may have with respect to the
                  Service, at info@versusmedia.app.
                </p>
                <strong>Last updated: April 18, 2023.</strong>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TermsOfUse;
