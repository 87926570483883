import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { appStoreImg, googlePlayImg, sliderImg } from "../images";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Home = () => {
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [x1, setX1] = useState("");
  const [y1, setY1] = useState("");
  const [x2, setX2] = useState("");
  const [y2, setY2] = useState("");
  const [x3, setX3] = useState("");
  const [y3, setY3] = useState("");
  useEffect(() => {}, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setX(Math.floor(Math.random() * (1300 - 10 + 1)) + 10);
      setY(Math.floor(Math.random() * (540 - 20 + 1)) + 20);

      setX1(Math.floor(Math.random() * (820 + 20 + 1)) + 20);
      setY1(Math.floor(Math.random() * (340 + 40 + 1)) + 40);

      setX2(Math.floor(Math.random() * (1200 - 0 + 1)) + 20);
      setY2(Math.floor(Math.random() * (240 - 30 + 1)) + 60);

      setX3(Math.floor(Math.random() * (1000 - 20 + 1)) + 20);
      setY3(Math.floor(Math.random() * (440 - 60 + 1)) + 60);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Layout>
        <div className="animation-box">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="animation">
                  <div className="blue-animation">
                    <motion.div
                      initial={{ x: 200, y: 700 }}
                      animate={{ x: x, y: y }}
                      whileInView={{ opacity: 1 }}
                      transition={{ ease: "easeOut", duration: 2 }}
                      exit={false}
                    >
                      <div className="bluecolor"></div>
                    </motion.div>
                  </div>
                  <div className="red-animation">
                    <motion.div
                      initial={{ x: 0, y: 500 }}
                      animate={{ x: x1, y: y1 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ ease: "easeOut", duration: 2 }}
                      exit={false}
                    >
                      <div className="redcolor"></div>
                    </motion.div>
                  </div>
                  <div className="blue-animation">
                    <motion.div
                      initial={{ x: 800, y: 300 }}
                      animate={{ x: x2, y: y2 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ ease: "easeOut", duration: 2 }}
                      exit={false}
                    >
                      <div className="bluecolor"></div>
                    </motion.div>
                  </div>
                  <div className="red-animation">
                    <motion.div
                      initial={{ x: 400, y: 100 }}
                      animate={{ x: x3, y: y3 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ ease: "easeOut", duration: 2 }}
                      exit={false}
                    >
                      <div className="redcolor"></div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-screen">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="home-screen-details">
                  <h1>
                  Unlock the Power of  <span className="red">Choice </span>
                  with <span className="blue"> VersusMedia</span>
                  </h1>
                  <p>
                  At Versus Media, we believe in the power of choice. We're here to help you make those day-to-day decisions with ease and confidence. Whether it's choosing your morning coffee or making more significant life choices, Versus Media is your trusted companion. Stay tuned for exciting updates, and thank you for being part of our journey!
                  </p>
                  <div className="download-btns mt-5">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.srh.versusmedia"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={googlePlayImg}
                        alt="google play"
                        className="img-fluid"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/asky-what-would-you-rather/id6448448278"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={appStoreImg}
                        alt="app store"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="home-screen-img">
                  <img src={sliderImg} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
                  <div className="d-flex mb-3 mb-lg-0">
                    <p className="me-4 mb-0">
                      <Link to="/privacy-policy" className="d-block">
                        Privacy Policy
                      </Link>
                    </p>
                    <p className="mb-0">
                      <Link to="/terms-of-use" className="d-block">
                        Terms of Use
                      </Link>
                    </p>
                  </div>
                  <p className="text-md-end text-center mb-3 mb-lg-0">
                    Copyright @ 2023 Versus Media. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
