import React from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <Layout>
        <section className="privacy-policy mt-5 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-4">Privacy Policy</h2>
                <p>
                  Welcome to VersusMedia, an online social Service that allows
                  users to ask questions or decisions based on images or text to
                  help them make decisions. Throughout these Terms, you will be
                  referred to as “you”, “your” or “user”.
                </p>
                <p>
                  Through the Service, you may access, view and post Content in
                  various forms, such as textual, visual, audio or any
                  combination thereof (collectively, the <strong>“Content”</strong> ).
                </p>
                <p>
                  The Service is owned and operated by VersusMedia ( <strong>“we”, “our”,
                  “us”</strong> ). Our designated applications for mobile devices
                  ( <strong>“Device”</strong> ), which constitute integral components of the
                  Service, will be referred to as the <strong>“App”</strong> or <strong>“Apps”.</strong>
                </p>
                <p>
                  We at VersusMedia, respect your privacy. This Policy (the
                  “Policy”) explains our privacy practices for the Service,
                  including the ways your personal information is collected and
                  used.
                </p>
                <p>
                  It is your decision to provide us with your personal
                  information. You are not legally obligated to provide or give
                  access to this information, but we do require it to let you
                  use the Service.
                </p>
                <p>
                  This Policy is incorporated by reference to the Service's
                  Terms of Use <a href="https://versusmedia.app/terms-of-use">https://versusmedia.app/terms</a> terms (the <strong>“Terms”</strong> )
                  and constitutes an integral part of them.
                </p>
                <h4 className="mb-2 mt-4">Key Points:</h4>
                <p>
                  The following key points of the privacy policy are only
                  displayed for your convenience. These key points do not
                  substitute the full privacy policy.
                </p>
                <table>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Information we collect</strong>
                    </td>
                    <td>
                      When you sign up for our Service You may need to provide
                      your email address, full name, phone number, payment
                      details or If you choose to sign up with your Facebook
                      account, you will provide us an access to your basic
                      Facebook profile. We collect analytical data and
                      information about your smartphone device. Content that you
                      choose to post on the Service may contain, your personal
                      information. Such Content, if shared, <strong>is not treated as
                      private or confidential and you should not have any
                      expectation of privacy with respect to it.</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Children's Privacy</strong>
                    </td>
                    <td>
                      We will not collect, use, or disclose any Personal Data
                      from a child if their parent or guardian does not provide
                      us his consent.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Use of collected information</strong>
                    </td>
                    <td>
                      We use the personal information we collect, to operate and
                      facilitate your use of the Service, advertise our Service,
                      to promote and market third party products and services,
                      develop new services, and for other ancillary purposes.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Sharing collected information </strong>
                    </td>
                    <td>
                      Your personal information will be shared with others in
                      order to operate and manage the Service, to serve you with
                      content, targeted ads, commercial discounts and for
                      ancillary purposes. You may also share content from the
                      Service, through the App or through third party platforms,
                      such as Facebook etc.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>
                        Retaining and controlling your personal information
                      </strong>
                    </td>
                    <td>
                      You may ask to review the personal information about you
                      that is stored in our systems, or have us update, correct
                      or delete it, subject to applicable law.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Data anonymization</strong>
                    </td>
                    <td>
                      We may anonymize personal information we have collected,
                      and use it (in its anonymized form) with others, for any
                      purpose.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Transfer of data outside your territory</strong>
                    </td>
                    <td>
                      You grant us permission to store and process your personal
                      information at locations outside your territory.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Information Security</strong>
                    </td>
                    <td>
                      We use measures to secure your personal information, but
                      they do not provide absolute information security.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Changes to this Privacy Policy</strong>
                    </td>
                    <td>
                      Substantial changes to this Policy will be announced
                      beforehand through our website or through the Service.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%", verticalAlign: "initial" }}>
                      <strong>Contact Us</strong>
                    </td>
                    <td>
                      You may send requests, responses, questions and comments
                      via email info@versusmedia.app.
                    </td>
                  </tr>
                </table>
                <h4>Information we collect</h4>

                <p>
                  <strong>Information you actively provide us</strong>
                </p>
                <p>
                  <strong>Registration information.</strong> To register as a
                  user and create a user account ( <strong>“Account”</strong> ), you will be asked
                  to provide your email address, full name, phone number and to
                  set a username, password and profile photo.
                </p>
                <p>
                  You may optionally provide us additional details about you,
                  such as your gender, date of birth and basic Facebook profile
                  information.
                </p>
                <p>
                  To register and subscribe as a subscriber and create an
                  Account you will be asked to provide your email address, full
                  name, phone number, payment details and to set a username,
                  password, your ID, your address, working hours, description
                  and profile photo.
                </p>
                <p>
                  <strong>Facebook Account.</strong> By providing us your username on third party
                  platforms, such as Facebook, you are allowing us access to
                  your public profile information on such third party platform.
                  We will collect and use your full name, profile image, gender
                  and location (your <strong>“Public Profile Information”</strong> ), only if they
                  are available to us according to your privacy settings on the
                  third party platform.
                </p>
                <p>
                  <strong>Google Account.</strong> By providing us your username on third party
                  platforms, such as Google, you are allowing us access to your
                  public profile information on such third party platform. We
                  will collect and use your full name, profile image, gender and
                  location (your <strong>“Public Profile Information”</strong> ), only if they are
                  available to us according to your privacy settings on the
                  third party platform.
                </p>
                <p>
                  <strong>Content</strong>
                </p>
                <p>
                  Content that you post on the Service may contain, or be
                  indicative of, your personal information, depending on the
                  particulars of the Content. Such Content, if shared,{" "}
                  <strong>
                    is not treated as private or confidential within the group
                    it is shared with and you should not have any expectation of
                    privacy with respect to it.
                  </strong>
                </p>
                <p>
                  because the content you upload may have to do with the privacy
                  of others, you must lawfully obtain the consent of all
                  individuals (and the consent of the parents or legal guardians
                  of all minors), who are depicted, shown or referred to in the
                  content, to include them in the content and to use that
                  content for the purposes and in the manner contemplated by
                  this policy and the terms.
                </p>
                <p>
                  in any case, use caution and common sense when you upload
                  content containing or depicting personal information of
                  yourself or other individuals. you are solely responsible for
                  any content that you upload.
                </p>
                <p>
                  <strong>Sharing content.</strong> You may choose to share with
                  others Content through the Service, or through third party
                  platforms, such as Facebook. Your use of such third party
                  platforms and the platforms, share or email through them, are
                  governed by their respective terms of use and privacy policy,
                  not this Policy. We may also share Content that you posted on
                  the Service, through Facebook or other social networks.
                </p>
                <p>
                  <strong>Information we automatically collect</strong>
                </p>
                <ul>
                  <li>
                    <strong>Analytical data.</strong> We may use third party
                    analytics tools, such as Google Analytics, to collect
                    information about your use of the Service. For example, we
                    record the frequency and scope of your use of the Service,
                    the duration of your sessions, the interactions and
                    communications you make with the Service’s interface, and
                    Content that you view or create while using the Service.{" "}
                  </li>
                  <li>
                    <strong>Device Information.</strong> We collect information
                    about your Device, including its model, operating system
                    type and version, unique Device identifiers, including MAC
                    address (a unique identifier assigned to your Device’s WiFi
                    component), the UDID (or “Open UDID” in iPhones), browser
                    type and language, mobile network information and the
                    Internet Protocol (IP) address through which you accessed
                    the Service.{" "}
                  </li>
                </ul>

                <h4>Children's Privacy</h4>

                <p>
                  If you are a child under 18 years of age, you may not use the
                  Service or provide any personal details to us without your
                  parent or legal guardian consent. We do not knowingly or
                  intentionally collect personal information of minors under 18
                  years of age without parental or legal guardian consent.
                </p>

                <h4>Use of collected information</h4>

                <p>
                  Your consent serves as the legal basis for processing your
                  information.
                </p>
                <p>
                  We use the personal information we collect for the following
                  purposes:
                </p>
                <ul>
                  <li>
                    To facilitate the access to and use of the Service, its
                    features and functionalities, develop, improve and customize
                    the Service, and to manage the administrative and
                    operational aspects of the Service;
                  </li>
                  <li>
                    To promote and market third party products and services,
                    subject to your right to object to such direct marketing as
                    explained below;{" "}
                  </li>
                  <li>
                    Send you updates and announcements, provide you with
                    support, handle complaints and contact you either directly
                    or through others on our behalf, including by e-mail
                    messages;
                  </li>
                  <li>
                    Enforce the Terms and this Policy and prevent unlawful
                    activities and misuse of the Service;
                  </li>
                  <li>
                    Comply with any applicable law and assist law enforcement
                    agencies and competent authorities;{" "}
                  </li>
                  <li>
                    Take any action in any case of dispute involving you, with
                    respect to the Service.
                  </li>
                </ul>
                <p>
                  <strong>Newsletters</strong>
                </p>
                <p>
                  We only send newsletters to registered users and all
                  newsletters we send contain an “unsubscribe” link.
                </p>
                <h4 className="mb-2 mt-4">Sharing collected information</h4>
                <p>
                  We share personal information outlined in this Policy, with
                  others, in the following instances:
                </p>
                <ul>
                  <li>
                    With our service providers, such as Aws, in order to
                    operate, maintain and enhance the Service;
                  </li>
                  <li>
                    With third parties you want to interact with, in order to
                    facilitate your interaction with them through the Service;{" "}
                  </li>
                  <li>
                    Content on the Service that you choose to share with others
                    may be viewable by other users of the Service, social
                    network users, and Internet users in general, along with
                    your name, username and other personal information outlined
                    above;
                  </li>
                  <li>
                    With third party ad service providers, to serve you with
                    targeted ads. Such ads may be provided to you in various
                    methods, including banners, interstitials, and specific
                    formats available on your Device;
                  </li>
                  <li>
                    If you have breached the Terms, abused your rights to use
                    the Service, or violated any applicable law. Your
                    information may be shared with competent authorities and
                    with any third party, as may be required;
                  </li>
                  <li>
                    With third parties to whom we believe that we are required
                    by law to disclose your information;{" "}
                  </li>
                  <li>
                    With attorneys, courts and relevant third parties, in any
                    case of dispute, or legal proceeding of any kind involving
                    you with respect to the Service;{" "}
                  </li>
                  <li>
                    If the operation of the Service is organized through another
                    legal structure or entity (such as due to a merger or
                    acquisition), provided that those entities agree to be bound
                    by the provisions of this Policy, with reasonably necessary
                    changes taken into consideration; and
                  </li>
                  <li>
                    We may share personal information with our corporate group
                    entities (companies that we control, control us, or are
                    under common control with us – whether directly or
                    indirectly), but their use of such information must comply
                    with the Policy.
                  </li>
                </ul>
                <p>
                  In any case other than the above, your personal information
                  will be shared with others only if you provide your explicit
                  prior consent.
                </p>
                <h4>Retaining and controlling your personal information</h4>
                <p>
                  We retain the personal information we collect for as long as
                  we deem necessary for our business needs, and delete it
                  thereafter from our systems, without notice to you. Please
                  remember that the Service does not provide back-up services
                  for any Content.
                </p>
                <p>
                  You may ask to access the personal information about you that
                  is stored in our systems, or have us update, correct or delete
                  such information, if applicable law provides you those rights.
                  If you wish to exercise any of these rights, contact us at:
                  info@versusmedia.app.
                </p>
                <p>
                  Please note that uninstalling the App from your Device will
                  not cause your personal information to be deleted from our
                  systems or from the Service.
                </p>
                <h4>Data anonymization</h4>
                <p>
                  At any time, we may anonymize (de-identify) personal
                  information we have collected. We may use the de-identified
                  information in any way, including by selling, or making it
                  available, to any other third party.
                </p>
                <h4>Transfer of data outside your territory</h4>
                <p>
                  We may store and process information in the EU and in other
                  countries such as the United States. We may also process
                  information using cloud services. The laws in those other
                  countries may provide a lower degree of data protection than
                  the laws of your own country. You agree to the transfer of
                  your information to such other countries for the purpose of
                  processing as described in this Policy, including through
                  cloud services.
                </p>
                <h4>Information security</h4>
                <p>
                  We implement measures to reduce the risks of damage, loss of
                  information and unauthorized access or use of information.
                  These measures do not provide absolute information security.
                  Therefore, it is not guaranteed and you cannot expect that the
                  App will be immune from information security risks.
                </p>
                <h4>Changes to this Privacy Policy</h4>
                <p>
                  We may change this Policy, in which case we will seek your
                  consent to those changes if they are material. If you do not
                  consent to the amended Policy, we may terminate your account.
                </p>
                <h4>Contact Us</h4>
                <p>
                  You may send requests, responses, questions and comments via
                  email: info@versusmedia.app.
                </p>
                <p>
                  <strong>Last Updated: April 18, 2023.</strong>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
